import React, { useEffect, useState } from "react";
import EmblaCarouselReact from "embla-carousel-react";
import useIntervalWithHooks from "./Utils/useIntervalWithHooks";
import { Image } from "../styles/Styles";
import auto from "../img/thumb-ondemand.png";
import avia from "../img/thumb-aviation.png";
import energy from "../img/thumb-energy.png";
import constr from "../img/thumb-construction.png";
import out from "../img/thumb-outdoor.png";
import rest from "../img/thumb-deliveries.png";
import tech from "../img/thumb-drones.png";


const industries = [
  {
    name: "オートモーティブ",
    benefits: ["特定の天候に関連した過去事例のハイパーローカルデータを入手", "過去分析に従い、起こりうる事象と行動を予測"],
    img: auto
  },
  {
    name: "航空",
    benefits: ["天候に関連した過去の安全事象を調査、分析", "天候に関連した過去の事象情報をもとに、フライト遅延の可能性を予測"],
    img: avia
  },
  {
    name: "建設",
    benefits: ["特定の過去事象を、過去の<nobr>気象</nobr>予報データのうち現場<nobr>での</nobr>決定を活用して分析", "将来的なプロジェクトの遅延や安全性の問題を、過去の<nobr>天候</nobr>関連プロジェクト事象を<nobr>活用</nobr>して、より正確に予測"],
    img: constr
  },
  {
    name: "エネルギー",
    benefits: ["ハイパーローカルな気象予報データを、特定の過去のビジネス事象分析のために入手", "将来的な停電と、安全性の問題を天候関連の過去事象から予測", "将来的な配分に備えるためにモデルを鍛錬"],
    img: energy
  },
  {
    name: "レストラン/小売り",
    benefits: ["将来の顧客ニーズを、天候と業績の相関性を理解して予測", "尋常でない天候が続く日々において、正しいスタッフ配置をして収益を最大化"],
    img: rest
  },
  {
    name: "スポーツ/アウトドア",
    benefits: ["気象予報と余暇の過ごし方の相関性を、過去事象を利用して理解", "より計画性をもって、スタッフ確保やロジスティックといった、将来事象のオペレーションニーズを予想"],
    img: out
  },
  {
    name: "テクノロジー",
    benefits: ["もっとも正確な過去データで、自社開発のAIモデルを<nobr>最適化</nobr>", "膨大な量のデータセットを<nobr>抽出</nobr>、分析するためにシームレスなアクセスを手に入れる"],
    img: tech
  }
];


export default () => {

  const [selected, setSelected] = useState(0);
  const [delay, setDelay] = useState(5000);
  const [embla, setEmbla] = useState(null);

  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        setSelected(embla.selectedScrollSnap);
      });
    }
  }, [embla]);

  const onSelect = i => {
    embla.scrollTo(i);
    setDelay(null);
  };

  useIntervalWithHooks(() => {
    embla.scrollNext();
  }, delay);

  return (
    <>
      <div className="d-flex flex-wrap justify-content-center fs-lg mb-3 mb-lg-4">
        {industries.map((item, i) => (
          <button
            onClick={() => onSelect(i)}
            key={i}
            className={`btn btn-link fs-lg px-1 px-md-2 fw-800 ${
              selected === i ? " text-black" : "text-muted"
            }`}
          >
            {item.name}
          </button>
        ))}
      </div>
      <EmblaCarouselReact emblaRef={setEmbla} options={{ loop: true }}>
        <div className="d-flex mb-4">
          {industries.map((industry, index) => (
            <div key={industry.name} style={{ flex: "0 0 100%" }}>
              <div className="px-4 py-3">
                <div className="row justify-content-center shadow-md rounded-lg overflow-hidden bg-white">
                  <div className="col-lg-4 pt-3 pb-4 pb-lg-2 px-4">
                    <h3 className="h-4 d-flex align-items-center">
                      {industry.name}
                    </h3>
                    <ul className="py-3 c-list">
                      {industry.benefits &&
                      industry.benefits.length > 0 &&
                      industry.benefits.map((benefit, i) => (
                        <li key={i} className="c-list-item-circle fs-lg mb-3" dangerouslySetInnerHTML={{__html: benefit}}/>
                      ))}
                    </ul>
                  </div>
                  <div className="col-lg-8 px-0 text-center">
                    <Image
                      width={450}
                      src={industry.img}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </EmblaCarouselReact>
    </>
  );
};
