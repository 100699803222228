import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Box, Button, Container, Image, Lead, T2, T3, T4 } from "../styles/Styles";
import loc from "../img/icon-hex-loc.svg";
import search from "../img/icon-hex-search.svg";
import sett from "../img/icon-hex-settings.svg";
import width from "../img/icon-hex-width.svg";
import ai from "../img/icon-hex-ai.svg";
import edit from "../img/icon-hex-edit.svg";
import done from "../img/icon-hex-done.svg";
import send from "../img/icon-hex-send.svg";
import theme from "../styles/theme";
import Clients from "../components/Homepage/Clients";
import LazyLoad from "react-lazyload";
import IndustriesCarouselHistorical from "../components/IndustriesCarouselHistorical";
import info from "../img/aq-info.png";
import insights from "../img/wai-insights.png";

const features = [
  {
    title: "もっとも正確な情報の入手",
    text: `<ul class="c-list"><li class="c-list-item-circle">グリッドデータのユニークな過去分析の利用</li><li class="c-list-item-circle">地球上の至る地点におけるハイパーローカルな結果を<nobr>入手</nobr></li></ul>`,
    img: info
  },
  {
    title: "AIによる貴重な<br/>ビジネスインサイトを生み出す",
    text: `<ul class="c-list"><li class="c-list-item-circle">天候と業績の相関性を見出す</li><li class="c-list-item-circle">AIモデルを鍛錬して新事業のインサイトを手に入れる</li></ul>`,
    img: insights
  }
];

const features2 = [
  {
    icon: loc,
    text: `ロケーション`
  }, {
    icon: search,
    text: `解像度`
  }, {
    icon: width,
    text: `適応範囲`
  }, {
    icon: sett,
    text: `パラメーター`
  }, {
    icon: done,
    text: `もっとも正確な<nobr>データ</nobr>`
  }, {
    icon: send,
    text: `シームレスな<nobr>デリバリー</nobr>`
  }, {
    icon: edit,
    text: `ニーズに応じて<nobr>カスタマイズ</nobr>`
  }, {
    icon: ai,
    text: `AIトレーニングに最適`
  }
];

export default ({ data, location }) => (
  <Layout>
    <SEO
      title="Weather History | Historical Weather Data | tomorrow.io"
      description="Understand weather history. Find, extract and analyze historical weather data to make better decisions. Get weather history data by the day, week, month, or year with details like temperature, humidity, wind"
      pathname={location.pathname}
      image={`https://www.tomorrow.io${data.hero.childImageSharp.fluid.src}`}
    />


    <Container>
      <Box display="flex" flexWrap="wrap">
        <Box
          width={[1, 1 / 2]}
          pt={["2rem", "4rem", "6rem"]}
          pr={[0, "2rem"]}
        >
          <T2 as="h1" mb={3}>
            WAI：AIのための過去の天気
          </T2>
          <Lead mb={4}>
            世界中いたる地域のもっとも正確な情報を手に入れ、<nobr>AIによる気づきで、</nobr>天気がビジネスに及ぼす影響を<nobr>より的確に予測</nobr>
          </Lead>
          <Button
            as="a"
            display={["block", "inline-block"]}
            mb={2}
            id="historical-link-form-1"
            href="https://Tomorrow.io.co/historical-weather-data/request/"
          >
            見積もりを依頼
          </Button>{" "}
          <Button
            as="a"
            href='https://Tomorrow.io.co/historical-weather-data/free/'
            outline="1"
            px={2}
            display={["block", "inline-block"]}
            width={["100%", "auto"]}
            mb={2}
          >
            無料でダウンロード
          </Button>
        </Box>
        <Box width={[1, 1 / 2]} pt={5}>
          <Img
            fluid={data.hero.childImageSharp.fluid}
            alt=""
          />
        </Box>
      </Box>
    </Container>

    <Container pt={6} pb={5}>
      <Clients/>
    </Container>

    <Box textAlign="center" py={[4, 5]} bg={theme.color.light}>
      <Container>
        <Box width={[1, 6 / 7]} mx="auto">
          <T3 as="h2" mb={5}>
            弊社の<nobr>大量の</nobr>データセットである、<nobr>ハイパーローカルな</nobr>過去の<nobr>気象予報データ</nobr>にすぐにアクセス
          </T3>

          <Box mb={5} textAlign="left" maxWidth={500} mx="auto">
            <Box display="flex" alignItems="center" mb={3}>
              <Image width={32} mr={3} src={done} alt=""/>
              <Lead mb={0} pt={1}>地球上のどのロケーションにも調整可能</Lead>
            </Box>
            <Box display="flex" alignItems="center">
              <Image width={32} mr={3} src={done} alt=""/>
              <Lead mb={0} pt={1}>ビジネスモデルによってカスタマイズ</Lead>
            </Box>
          </Box>
          <T3 mb={5}>必要とするものを選択：</T3>
          <Box width={[1, 3 / 4]} mx="auto" display="flex" justifyContent="center" flexWrap="wrap">
            {features2.slice(0, 4).map((item, i) => (
              <Box width={[1, 1 / 2, 1 / 4]} mb={5} textAlign="center" key={i}>
                <Image mb={2} src={item.icon} alt=""/>
                <Box mx="auto" width={[1, 2 / 3]}><strong dangerouslySetInnerHTML={{__html: item.text}}/></Box>
              </Box>
            ))}
          </Box>
          <Button
            as="a"
            display={["block", "inline-block"]}
            mb={2}
            id="historical-link-form-2"
            href="https://Tomorrow.io/historical-weather-data/request/"
          >
            見積もりを依頼
          </Button>
        </Box>
      </Container>
    </Box>

    <Container>
      <Box py={5} px={[0, 6, 0]}>
        <T3 as="h2" mb={[5, "6rem"]} textAlign="center">
          WAIで何ができるのでしょう？
        </T3>
        {features.map((item, i) => {
          let even = (i + 1) % 2 === 0;
          return (
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              key={i}
              mb={6}
            >
              <Box
                width={[1, 1, 1 / 2]}
                pr={[0, 0, even ? 0 : 4]}
                pl={[0, 0, even ? 4 : 0]}
                order={[1, 1, even ? 2 : 1]}
              >
                <T4 as="h3" mb={3} dangerouslySetInnerHTML={{ __html: item.title }}/>
                <Lead as="div" mb={3} dangerouslySetInnerHTML={{ __html: item.text }}/>
              </Box>
              <Box
                width={[1, 1, 1 / 2]}
                order={[2, 2, even ? 1 : 2]}
                textAlign="center"
              >
                <LazyLoad height={500} once>
                  <Image src={item.img} maxHeight={[300, 400]} maxWidth={["100%", 500]} borderRadius={8} mx="auto"/>
                </LazyLoad>
              </Box>
            </Box>
          );
        })}
        <Box textAlign="center">
          <Button
            as="a"
            display={["block", "inline-block"]}
            mb={3}
            id="historical-link-form-3"
            href="https://Tomorrow.io/historical-weather-data/request/"
          >
            見積もりを依頼
          </Button>
        </Box>
      </Box>
    </Container>

    <Box pt={5}>
      <Container>
        <T3 mb={5} textAlign="center">
          WAIに向いているのは？
        </T3>
        <LazyLoad height={600} offset={600} once>
          <Box mx="auto" mb={5} style={{ maxWidth: 1000 }}>
            <IndustriesCarouselHistorical/>
          </Box>
        </LazyLoad>
      </Container>
    </Box>

    <Box py={5} bg={theme.color.light}>
      <Container>
        <T3 mb={5} textAlign="center">なぜtomorrow.ioのWAIを選ぶのか？</T3>
        <Box width={[1, 3 / 4]} mx="auto" display="flex" justifyContent="center" flexWrap="wrap">
          {features2.slice(4, 8).map((item, i) => (
            <Box width={[1, 1 / 2, 1 / 4]} mb={5} textAlign="center" key={i}>
              <Image mb={2} src={item.icon} alt=""/>
              <Box mx="auto" width={[1, 2 / 3]}><strong dangerouslySetInnerHTML={{__html: item.text}}/></Box>
            </Box>
          ))}
        </Box>
        <Box textAlign="center">
          <Button
            as="a"
            display={["block", "inline-block"]}
            mb={3}
            id="historical-link-form-4"
            href="https://Tomorrow.io/historical-weather-data/request/"
          >
           見積もりを依頼
          </Button>
        </Box>
      </Container>
    </Box>

  </Layout>
);

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "historical-hero.png" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
