import React from "react";
import styled from "styled-components";
import united from "../../img/united-logo-color.svg";
import usta from "../../img/usta-logo-color.svg";
import patriots from "../../img/patriots-logo-color.svg";
import national from "../../img/nationalgrid-logo-color.svg";
import jetblue from "../../img/jetblue-logo-color.svg";
import ford from "../../img/ford-logo-color.svg";
import delta from "../../img/delta-logo-color.svg";
import via from "../../img/via-logo-color.svg";
import aws from "../../img/aws-logo-color.svg";
import uber from "../../img/uber-logo.svg";

const logos = [
  uber,
  jetblue,
  via,
  usta,
  united,
  delta,
  national,
  patriots,
  ford,
  aws
];

const Clients = () => {
  return (
    <Wrap>
      {logos.map(logo => (
        <ImgWrap key={logo}>
          <img src={logo} alt=""/>
        </ImgWrap>
      ))}
    </Wrap>
  );
};

export default Clients;

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: 768px) {
    justify-content: space-around;
  }
`;

const ImgWrap = styled.div`
  flex: 1 0 50%;
  max-width: 50%;
  text-align: center;
  margin-bottom: 2.5rem;
  @media screen and (min-width: 769px) {
    flex: 1 0 20%;
  max-width: 20%;
  }
  img {
    max-width: 80px;
    max-height: 40px;
    height: auto;
    @media screen and (min-width: 769px) {
      max-width: 105px;
      max-height: 28px;
    }
  }
`;
